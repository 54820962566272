import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import { COLOR_COUNT_0, COLOR_RANK_0 } from '../colors';
import { formatCountValue } from '../utils';

import { HorizontalColumnChart, MicroBarChart, MicroLineChart, SolidGauge } from '../components/charts';
import { Row333333, Row5050 } from '../components/grid';
import { ExternalLinkIcon } from '../components/icons';
import { CountMetric, RankMetric } from '../components/metrics';
import { Paper, PaperPadding, PaperText, PaperTitle } from '../components/papers';
import { Ranking, RankingLink, RankingTable } from '../components/rankings';
import { AppLayout } from '../layout';

/**
 *
 */
/* eslint-disable-next-line max-lines-per-function */
export function Technology(props) {
  const { appData, category, charts, details, metrics } = props.pathContext;

  return (
    <AppLayout data={appData} title={`Trends for ${details.name_display}`}>
      <Helmet>
        <title>
          Trends for {details.name_display} | {details.category.name_display} | Technologies | StackTrends
        </title>
        <meta name="description" content="" />
      </Helmet>

            {/* -- listings -- */}
      <Row333333>
        <RankMetric
          value={`Rank ${metrics.currentItem.rank ?? '-'} of ${metrics.currentListItemCount}`}
          label="Current"
        />

        <CountMetric value={`${formatCountValue(metrics.currentItem.count)} Listings`} label="Current" />

        <CountMetric value={`${formatCountValue(metrics.lifetimeListingCount)} Listings`} label="Historic" />
      </Row333333>
      <Paper>
        <PaperTitle>Number of Jobs Over Time</PaperTitle>
        <PaperPadding>
          <MicroBarChart
            chartId="micro_bar_chart_technology_listing_count"
            color={COLOR_COUNT_0}
            data={charts.microBarListingCount}
          />
        </PaperPadding>
      </Paper>
      <Paper>
        <PaperTitle>Job Rank in {details.category.name_display}</PaperTitle>

        <PaperPadding>
          <MicroLineChart
            chartId="micro_line_chart_technology_rank"
            color={COLOR_RANK_0}
            data={category.microLineRank}
            inverse={true}
          />
        </PaperPadding>

        <Ranking>
          <RankingTable data={category.currentRankingSegment} parentPath={`technologies/${details.category.name}`} />
          <RankingLink to={`/technologies/${details.category.name}/`}>View All Results</RankingLink>
        </Ranking>
      </Paper>

      {/* -- about -- */}
      <Paper>
          <PaperTitle>About {details.name_display}</PaperTitle>
          <PaperPadding>
            {details.description} Learn more about {details.name_display}{' '}
            <a href={details.brand_reference_url} target="_blank" rel="noopener noreferrer">
              here {ExternalLinkIcon}.
            </a>
          </PaperPadding>
        </Paper>

      {/* -- location -- */}
      <Row5050>
        {/* -- city -- */}

        <Paper>
          <PaperTitle>{details.name_display} Jobs by City</PaperTitle>

          <HorizontalColumnChart
            chartId={`column_chart_jobs_by_city`}
            chartColor={COLOR_COUNT_0}
            data={charts.jobsByCity}
            height="512px"
          />
        </Paper>

        <Paper>
          <PaperTitle>{details.name_display} Popularity by City</PaperTitle>
          <HorizontalColumnChart
            chartId={`column_chart_popularity_by_city`}
            chartColor={COLOR_RANK_0}
            data={charts.popularityByCity}
            height="512px"
            isPercent={true}
          />
        </Paper>

        {/* -- state -- */}

        <Paper>
          <PaperTitle>{details.name_display} Jobs by State</PaperTitle>
          <HorizontalColumnChart
            chartId={`column_chart_jobs_by_state`}
            chartColor={COLOR_COUNT_0}
            data={charts.jobsByState}
            height="1024px"
          />
        </Paper>

        <Paper>
          <PaperTitle>{details.name_display} Popularity by State</PaperTitle>
          <HorizontalColumnChart
            chartId={`column_chart_popularity_by_state`}
            chartColor={COLOR_RANK_0}
            data={charts.popularityByState}
            height="1024px"
            isPercent={true}
          />
        </Paper>
      </Row5050>

      {/* -- related -- */}
        <Paper>
          <PaperTitle>Related Technologies</PaperTitle>

          <PaperPadding>
            <Row5050>
            <SolidGauge chartId={`radar_chart_${details.name}_commonly_seen_with`} data={charts.radarSeenWith} />
            <PaperText>
              <p>
                These are the technologies that appear most often in job listings that contain {details.name_display}.
                Results are not limited to{' '}
                <Link to={`/technologies/${details.category.name}/`}>
                  {details.category.name_display} {ExternalLinkIcon}
                </Link>{' '}
                and can represent technologies that are part of the same stack (IE React, JavaScript, HTML, and CSS) or
                close competitors in the same category (IE React, Angular, and AngularJS).
              </p>
              <p>
                Results may not be reciprocal. For example, Java often appears in Scala listings but Scala is not one of
                the most common technologies in Java listings.
              </p>
              <p>
                For more information about job listing content{' '}
                <Link to="/faq#why_job_listings_have_multiple_technology">visit the FAQ {ExternalLinkIcon}</Link>.
              </p>
            </PaperText>
            </Row5050>
          </PaperPadding>
        </Paper>
    </AppLayout>
  );
}

export default Technology;
