import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faChartLine, faExternalLinkAlt, faSearch } from '@fortawesome/free-solid-svg-icons';

import { COLOR_COUNT_0, COLOR_LINK_0, COLOR_RANK_0, COLOR_SEARCH_0 } from '../colors';

export const CountIcon = <FontAwesomeIcon icon={faChartBar} style={{ color: COLOR_COUNT_0 }} />;

export const ExternalLinkIcon = <FontAwesomeIcon icon={faExternalLinkAlt} style={{ color: COLOR_LINK_0 }} />;

export const RankIcon = <FontAwesomeIcon icon={faChartLine} style={{ color: COLOR_RANK_0 }} />;

export const SearchIcon = <FontAwesomeIcon icon={faSearch} style={{ color: COLOR_SEARCH_0 }} />;
